/* Ginto (body typeface) */

@font-face {
  font-family: "Ginto";
  src:
    url("/fonts/Ginto/ABCGintoNormal-Regular.woff2") format("woff2"),
    url("/fonts/Ginto/ABCGintoNormal-Regular.woff") format("woff");
}

@font-face {
  font-family: "Ginto";
  font-style: italic;
  src:
    url("/fonts/Ginto/ABCGintoNormal-RegularItalic.woff2") format("woff2"),
    url("/fonts/Ginto/ABCGintoNormal-RegularItalic.woff") format("woff");
}

@font-face {
  font-family: "Ginto";
  font-weight: 500;
  src:
    url("/fonts/Ginto/ABCGintoNormal-Medium.woff2") format("woff2"),
    url("/fonts/Ginto/ABCGintoNormal-Medium.woff") format("woff");
}

@font-face {
  font-family: "Ginto";
  font-style: italic;
  font-weight: 500;
  src:
    url("/fonts/Ginto/ABCGintoNormal-MediumItalic.woff2") format("woff2"),
    url("/fonts/Ginto/ABCGintoNormal-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "Ginto";
  font-weight: 700;
  src:
    url("/fonts/Ginto/ABCGintoNormal-Bold.woff2") format("woff2"),
    url("/fonts/Ginto/ABCGintoNormal-Bold.woff") format("woff");
}

@font-face {
  font-family: "Ginto";
  font-style: italic;
  font-weight: 700;
  src:
    url("/fonts/Ginto/ABCGintoNormal-BoldItalic.woff2") format("woff2"),
    url("/fonts/Ginto/ABCGintoNormal-BoldItalic.woff") format("woff");
}

/* GT Super Display (body typeface) */

@font-face {
  font-family: "GT Super";
  src:
    url("/fonts/GT-Super/GT-Super-Display-Regular.woff2") format("woff2"),
    url("/fonts/GT-Super/GT-Super-Display-Regular.woff") format("woff"),
    url("/fonts/GT-Super/GT-Super-Display-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "GT Super";
  font-style: italic;
  src:
    url("/fonts/GT-Super/GT-Super-Display-Regular-Italic.woff2") format("woff2"),
    url("/fonts/GT-Super/GT-Super-Display-Regular-Italic.woff") format("woff"),
    url("/fonts/GT-Super/GT-Super-Display-Regular-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "GT Super";
  font-weight: 500;
  src:
    url("/fonts/GT-Super/GT-Super-Display-Medium.woff2") format("woff2"),
    url("/fonts/GT-Super/GT-Super-Display-Medium.woff") format("woff"),
    url("/fonts/GT-Super/GT-Super-Display-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "GT Super";
  font-weight: 500;
  font-style: italic;
  src:
    url("/fonts/GT-Super/GT-Super-Display-Medium-Italic.woff2") format("woff2"),
    url("/fonts/GT-Super/GT-Super-Display-Medium-Italic.woff") format("woff"),
    url("/fonts/GT-Super/GT-Super-Display-Medium-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Indie Flower";
  src: url("/fonts/Indie-Flower/IndieFlower-Regular.ttf") format("truetype");
}

body {
  font-weight: 400;
  font-family: "Ginto", sans-serif;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

h1 {
  font-weight: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 125%;
  margin-bottom: 20px;
  margin-top: 0;
  letter-spacing: -0.02em;
  text-wrap: balance;
}

h2 {
  font-size: 48px;
  font-weight: normal;
  line-height: 125%;
  margin-top: 20px;
  margin-bottom: 10px;
  letter-spacing: -0.02em;
  text-wrap: balance;
}

h2.numbered {
  font-weight: bold;
  font-size: 34px;
  margin-top: 20px;
  margin-bottom: 18px;
  line-height: 36px;
}

h2.numbered:before {
  background-color: black;
  color: white;
}

h3 {
  font-size: 34px;
  line-height: 125%;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 10px;
  text-wrap: balance;
}

h4 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 4px;
}

h5 {
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 6px;
}

h6 {
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0;
  font-weight: 500;
}

p {
  margin-top: 0;
  line-height: 24px;
}

input,
textarea,
select,
fieldset {
  margin-bottom: 15px;
}
pre,
blockquote,
dl,
figure,
table,
p,
ul,
ol {
  margin-bottom: 15px;
}

figure {
  margin: 20px 0;
}

figure > img {
  width: 100%;
}

.numbered {
  display: inline-block;
  position: relative;
}

.numbered:before {
  content: attr(data-count);
  position: absolute;
  left: -50px;
  width: 36px;
  height: 36px;
  text-align: center;
  font-size: 18px;
  line-height: 36px;
  font-weight: 600;
}

.subhead {
  margin-top: 20px;
  font-size: 22px;
  line-height: 32px;
}

.bigtext {
  font-size: 18px;
}

a,
.link {
  color: var(--candor-dark-gold);
  text-decoration: underline;
  cursor: pointer;
}

/* .dark a,
.dark .link {
  border-bottom-color: white;
} */

a:hover,
.link:hover {
  color: var(--candor-medium-gold);
}
/* 
.dark a:hover,
.dark .link:hover {
} */

ol,
ul {
  padding-left: 0;
  margin-top: 0;
}
ul ul,
ul ol,
ol ol,
ol ul {
  margin: 1rem 0 1rem 2rem;
}

li {
  margin-left: 28px;
  padding-bottom: 6px;
}

li > ul {
  margin: 6px 0 0;
}

.content ol {
  counter-reset: list;
}

.content ol > li {
  margin-left: 34px;
}

.content ol > li:before {
  content: counter(list);
  counter-increment: list;
  float: left;
  margin-top: 3px;
  margin-left: -26px;
  width: 18px;
  height: 18px;
  color: white;
  background-color: black;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  vertical-align: middle;
}

form label {
  font-weight: 500;
  margin-bottom: 6px;
}

code {
  padding: 2px 5px;
  margin: 0 2px;
  font-size: 90%;
  white-space: nowrap;
  background: #f1f1f1;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
}

pre > code {
  display: block;
  padding: 10px 15px;
  white-space: pre;
}

blockquote {
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  border-left: 2px solid #cfd8f6;
  color: #7b7d80;
  margin: 25px 0;
  padding-left: 15px;
  margin-left: 2px;
}

hr {
  margin-top: 30px;
  margin-bottom: 35px;
  border-width: 0;
  border-top: 1px solid #e1e1e1;
}

.formula {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 6px 10px;
  margin-left: 8px;
}

.seperator {
  opacity: 0.6;
  padding: 0 4px;
}

.nowrap {
  white-space: nowrap;
}

::selection {
  background: var(--candor-neon-green);
  color: black;
}
/*
 * Forms
 */
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
input[type="date"],
textarea,
select {
  height: 40px;
  font-size: 16px;
  padding: 6px 10px; /* The 6px vertically centers text on FF, ignored by Webkit */
  box-shadow: none;
  border: 1px solid #cfd3d6;
  background-color: white;
  border-radius: 4px;
  transition: all 150ms ease-in-out;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 10 6'%3E%3Cpath fill='gray' d='M5 6L0 1h10L5 6z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  padding-right: 20px;
}

.cl-input {
  padding: 0.375rem 0.75rem !important;
  background-color: white !important;
  font-size: 0.8125rem !important;
  border-radius: 0.375rem !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: rgba(0, 0, 0, 0.11) !important;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 1px 0px !important;
  transition-property: background-color, background, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
  transition-timing-function: ease !important;
  transition-duration: 200ms !important;
}

.cl-input[type="tel"]:focus {
  border-color: unset !important;
  box-shadow: unset !important;
}

.cl-input[type="tel"] {
  border-radius: 0px 0.375rem 0.375rem 0px !important;
  border-width: 0px !important;
  padding-left: 0.25rem !important;
}

.dark input[type="email"],
.dark input[type="number"],
.dark input[type="search"],
.dark input[type="text"],
.dark input[type="tel"],
.dark input[type="url"],
.dark input[type="password"],
.dark input[type="date"],
.dark textarea,
.dark select {
  /* border-color: #F4C88F; */
  color: black;
  border: none;
}

/* .dark input[type="email"]::placeholder,
.dark input[type="number"]::placeholder,
.dark input[type="search"]::placeholder,
.dark input[type="text"]::placeholder,
.dark input[type="tel"]::placeholder,
.dark input[type="url"]::placeholder,
.dark input[type="password"]::placeholder,
.dark input[type="date"]::placeholder,
.dark textarea::placeholder,
.dark select::placeholder {
  color: rgba(255, 255, 255, 0.4);
} */

/* Removes awkward default styles on some inputs for iOS */
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
input[type="date"],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

textarea {
  padding-top: 6px;
  padding-bottom: 6px;
}

input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="date"]:focus {
  /* background-color: rgba(12, 53, 59, 0.1); */
  border-width: 2px;
  box-shadow: 0px 0px 0px 3px rgba(94, 107, 104, 0.2);
  outline: 0;
}

.dark input[type="email"]:focus,
.dark input[type="number"]:focus,
.dark input[type="search"]:focus,
.dark input[type="text"]:focus,
.dark input[type="tel"]:focus,
.dark input[type="url"]:focus,
.dark input[type="password"]:focus,
.dark input[type="date"]:focus,
.dark textarea:focus,
.dark select:focus {
  border-width: 2px;
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.2);
  outline: 0;
}

.cl-input:focus,
.dark .cl-input:focus {
  border-width: 0px !important;
  box-shadow:
    rgba(0, 0, 0, 0.11) 0px 0px 1px 0px,
    rgba(0, 0, 0, 0.11) 0px 0px 0px 4px !important;
}

input[type="email"][disabled],
input[type="number"][disabled],
input[type="search"][disabled],
input[type="text"][disabled],
input[type="tel"][disabled],
input[type="url"][disabled],
input[type="password"][disabled],
input[type="date"][disabled],
textarea[disabled],
select[disabled] {
  opacity: 0.5;
  user-select: none;
  cursor: not-allowed;
}

label,
legend {
  display: block;
  margin-bottom: 2px;
  font-weight: 600;
}

fieldset {
  padding: 0;
  border-width: 0;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
}

label > .label-body {
  display: inline-block;
  margin-left: 0.5px;
  font-weight: normal;
}

input.select__input {
  margin: 0;
  box-shadow: none !important;
  height: auto !important;
  transition: none !important;
}

/*
 * Article elements
 */

blockquote.email {
  display: inline-block;
  border: none;
  margin: 30px -20px 30px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  padding: 20px 20px 15px;
  font-size: 15px;
  background-color: white;
  font-style: normal;
  color: black;
}

blockquote.email .subject {
  display: block;
  margin-bottom: 18px;
  padding-bottom: 14px;
  border-bottom: 1px solid #d6d6d6;
}

blockquote.email .subject::before {
  content: "Subject: ";
  font-weight: bold;
}

/*
 * Tables
 */

th {
  font-weight: 500;
}

th,
td {
  padding: 12px 15px;
  text-align: left;
  vertical-align: middle;
  border-bottom: 1px solid var(--candor-grey-30);
}
th:first-child,
td:first-child {
  padding-left: 0;
}

th:last-child,
td:last-child {
  padding-right: 0;
}

/* Mobile */
@media (max-width: 600px) {
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 34px;
  }

  h3 {
    font-size: 24px;
    line-height: 32px;
  }
}

@media (max-width: 900px) {
  h2.numbered {
    margin-left: 48px;
  }

  blockquote.email {
    margin: 20px -5px 20px;
  }
}
