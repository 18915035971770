.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 20px !important;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__day {
  width: 2rem !important;
}

.react-datepicker__day-name {
  color: rgba(94, 107, 104, 0.5) !important;
}

.react-datepicker__current-month {
  font-family: Ginto !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: center !important;

  color: rgba(23, 52, 58, 1) !important;
  margin-bottom: 20px !important;
}

.react-datepicker__day-name {
  width: 2rem !important;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: rgba(240, 240, 240, 1) !important;
  color: rgba(23, 52, 58, 1) !important;
}

.react-datepicker__day--selected {
  background-color: rgba(0, 0, 0, 1) !important;
  color: rgba(255, 255, 255, 1) !important;
}

.react-datepicker__header {
  margin-top: 15px !important;
  background-color: rgba(240, 240, 240, 0) !important;

  border-bottom: 0px !important;
  margin-bottom: -5px !important;
}

.react-datepicker__navigation {
  top: 20px !important;
}

.react-datepicker__navigation *::before {
  border-color: rgba(23, 52, 58, 1) !important;
  font-size: 10px !important;
}

.react-datepicker__navigation-icon::before {
  border-width: 2px 2px 0 0 !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: rgba(0, 0, 0, 0) !important;
  color: #000000 !important;
}

.react-datepicker__day--keyboard-selected.react-datepicker__day--disabled {
  background-color: #ffffff !important;
  color: #ccc !important;
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: #f0f0f0 !important;
}
.react-datepicker__day--keyboard-selected.react-datepicker__day--disabled:hover {
  background-color: #ffffff !important;
}
.react-datepicker__day--in-range {
  background-color: rgba(0, 0, 0, 1) !important;
  color: rgba(255, 255, 255, 1) !important;
}

.react-datepicker {
  min-height: 310px;
}
