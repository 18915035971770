:root {
  --candor-dark-green: #759500;
  --candor-neon-green: #d2ed64;
  --candor-tertiary-green: #ebfaac;
  --candor-light-green: #e8f6b1;
  --candor-lightest-green: #f4fbd8;
  --candor-dark-gold: #a07850;
  --candor-medium-gold: #c8a06e;
  --candor-black: #0f0f0f;
  --candor-grey-100: #101010;
  --candor-grey-90: #232323;
  --candor-grey-80: #3d3d3d;
  --candor-grey-70: #6f7273;
  --candor-grey-60: #8d9192;
  --candor-grey-50: #c3c6c7;
  --candor-grey-30: #e5e7e8;
  --candor-background-grey: #f8f9f9;
  --candor-white: #ffffff;
  --alert-red: #c83532;
  --alert-green: #3d7d40;
  --alert-yellow: #f9dc7b;
  --primary-white: #ffffff;
  --grey-2: #cfd3d6;
  --grey-5: #6b7075;

  --radius-l: 12px;

  --p-space-8: 32px;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/*
 * Base
 */

body {
  overflow-x: hidden;
  background-color: var(--candor-background-grey);
}

body.white {
  background-color: white;
}

body.no-bounce {
  overscroll-behavior-y: none;
}

body.black {
  background: #0f0f0f;
}

body.dark {
  background: linear-gradient(141.24deg, #04171a 6.36%, #08282d 95.2%);
}

html,
body,
#__next {
  width: 100%;
  height: 100%;
}

body.noheight {
  height: auto;
}

body.fullheight,
body.hasoverlay,
.no-scroll {
  overflow: hidden;
}

body.fullheight #__next,
body.flex #__next {
  display: flex;
  flex-direction: column;
}

body.flex #__next {
  min-height: 100vh;
}

.container {
  width: 100%;
  max-width: 820px;
  margin: 0 auto;
  padding: 0 20px;
}

.widecontainer {
  position: relative;
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}

.widecontainer-primary {
  flex: 0 0 60%;
}

.widecontainer-secondary {
  flex: 0 0 40%;
}

.fullsize {
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
}

.flex {
  display: flex;
}

.notice {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  padding-bottom: 200px;
  padding-top: 120px;
}

.dark {
  color: white;
}

/*
 Opener
*/

.opener .widecontainer-primary {
  padding-top: 20px;
  width: 60%;
}

.opener.salary-negotation .widecontainer-secondary,
.opener.rsu-compensation .widecontainer-secondary,
.opener.howtoquit .widecontainer-secondary,
.opener.perfreviews .widecontainer-secondary,
.opener.layoff .widecontainer-secondary,
.opener.workfromhome .widecontainer-secondary {
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
  width: 40%;
}

.opener.salary-negotation .widecontainer-secondary {
  top: -40px;
  right: 0;
  height: calc(100% + 50px);
  background-image: url(/images/guides/salary-negotiation.jpg);
}

.opener.rsu-compensation .widecontainer-secondary {
  top: -60px;
  right: -20px;
  height: calc(100% + 60px);
  background-image: url(/images/guides/rsu-compensation.jpg);
}

.opener.howtoquit .widecontainer-secondary {
  top: -60px;
  right: 20px;
  height: calc(100% + 80px);
  background-image: url(/images/guides/how-to-quit.jpg);
}

.opener.perfreviews .widecontainer-secondary {
  top: -60px;
  right: 0;
  height: calc(100% + 70px);
  background-image: url(/images/guides/performance-reviews.jpg);
}

.opener.layoff .widecontainer-secondary {
  top: -30px;
  right: 0;
  height: calc(100% + 40px);
  background-image: url(/images/guides/layoff.jpg);
}

.opener.workfromhome .widecontainer-secondary {
  top: -110px;
  right: 0;
  height: calc(100% + 120px);
  background-image: url(/images/guides/work-from-home.jpg);
}

.opener {
  padding: 35px 0 40px;
}

.opener h1 {
  max-width: 700px;
  font-weight: 500;
  font-size: 50px;
  line-height: 125%;
}

.opener p {
  font-size: 18px;
}

.opener .toc {
  margin-top: 20px;
  padding-top: 30px;
  border-top: 2px solid rgba(12, 53, 59, 0.1);
}

.opener .toc > h5 {
  margin-bottom: 10px;
}

.opener ol.toc li {
  font-size: 18px;
  line-height: 26px;
  counter-increment: step-counter;
  position: relative;
  margin-left: 35px;
  padding-bottom: 18px;
}

.opener ol.toc li:last-child {
  padding-bottom: 0;
}

.opener ol.toc li:before {
  content: counter(step-counter);
  position: absolute;
  left: -35px;
  width: 24px;
  height: 24px;
  color: white;
  background-color: black;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
}

.opener ol.toc li a {
  padding: 0;
  font-weight: bold;
  border-bottom: none;
  text-decoration: none;
}

.opener ol.toc li a:hover {
  background: none;
  box-shadow: none;
  text-decoration: underline;
}

.content.container {
  position: relative;
}

.content h2 {
  font-size: 34px;
  font-weight: bold;
}

.content h3 {
  font-size: 24px;
}

.content.last {
  margin-bottom: 80px;
}

.content .opener {
  margin-top: 60px;
}

.content.deck {
  font-size: 18px;
  margin-bottom: 40px;
  margin-top: 50px;
  border-bottom: 2px solid rgba(12, 53, 59, 0.1);
  padding-top: 20px;
  padding-bottom: 20px;
}

.guide-figure {
  width: 100%;
  width: calc(100% + 40px);
  margin: 15px -20px;
  border: 4px solid #f2edfb;
}

/*
 * Alert
 */

.alert {
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 25px;
  border: 1px solid transparent;
}

.alert.success {
  background-color: #d4edda;
  border-color: #155724;
}

.alert.error {
  background-color: #f8d7da;
  border-color: #721c24;
}

/*
 * Articles
 */
.optional,
aside {
  font-size: 15px;
  padding: 10px 18px 14px;
  float: right;
  width: 250px;
  margin-top: -120px;
  margin-right: -280px;
  border-left: 1px solid rgba(12, 53, 59, 0.2);
}

.optional:before,
aside:before {
  content: "❉ TIP";
  display: block;
  color: black;
  font-weight: 900;
  letter-spacing: 1px;
  font-size: 13px;
  margin-bottom: 4px;
}

.predialog {
  margin-bottom: 0;
}

/*
 * Article
 */

/* .widecontainer.title h1 {
  padding-right: 30%;
  margin-top: 10px;
  margin-bottom: 0;
} */

.opener-sidebar {
  margin-left: 30px;
  padding-left: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-left: 1px solid white;
}

.opener-sidebar ul {
  margin-top: 12px;
  margin-bottom: 20px;
}

.opener-sidebar,
.opener-sidebar > p {
  font-size: 16px;
}

.opener-sidebar > p:last-child {
  margin-bottom: 0;
}

.guide-seperator {
  margin-top: 50px;
  margin-bottom: 50px;
  border-bottom: 2px solid rgba(12, 53, 59, 0.1);
}

.block {
  margin-top: 40px;
}

.fullsize > iframe {
  border: none;
  width: 100%;
  width: calc(100% - 100px);
  height: 100%;
  height: calc(100% - 50px);
  padding: 30px;
  box-shadow: 0 0 14px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  background-color: white;
}

/*
  Modal
*/

.ReactModal__Overlay--after-open {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
}

.modal {
  position: relative;
  background-color: white;
  max-width: 800px;
  margin: auto;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  z-index: 1000;
  outline: none;
}

.modal > svg:first-child {
  position: absolute;
  top: 15px;
  right: 15px;
  display: block;
  width: 25px;
  height: 25px;
  cursor: pointer;
  z-index: 1001;
}

.modal > svg:first-child > path {
  fill: rgba(0, 0, 0, 0.5);
}

.modal > svg:first-child:hover > path {
  fill: #0c171a;
}

/*
 * Clerk
 */

.cl-button {
  box-shadow: none !important;
}

.cl-formButtonPrimary {
  padding-top: 10px;
  padding-bottom: 10px;
}

.cl-socialButtonsBlockButton {
  border: 1px solid #cfd3d6 !important;
  padding-top: 9px;
  padding-bottom: 9px;
}

.cl-formFieldInput {
  border-color: #cfd3d6;
}

.cl-formFieldLabel {
  font-weight: 500;
  margin-bottom: 0;
}

/*
  Tooltip
*/

.rc-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
}

.rc-tooltip-hidden {
  display: none;
}

.rc-tooltip-placement-top,
.rc-tooltip-placement-topLeft,
.rc-tooltip-placement-topRight {
  padding: 5px 0 9px 0;
}
.rc-tooltip-placement-right,
.rc-tooltip-placement-rightTop,
.rc-tooltip-placement-rightBottom {
  padding: 0 5px 0 9px;
}
.rc-tooltip-placement-bottom,
.rc-tooltip-placement-bottomLeft,
.rc-tooltip-placement-bottomRight {
  padding: 9px 0 5px 0;
}
.rc-tooltip-placement-left,
.rc-tooltip-placement-leftTop,
.rc-tooltip-placement-leftBottom {
  padding: 0 9px 0 5px;
}

.rc-tooltip-inner {
  padding: 20px;
  background-color: black;
  color: white;
  text-align: left;
  max-width: 300px;
  border-radius: 4px;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.rc-tooltip-inner > p {
  font-size: 14px;
  line-height: 20px;
}

.rc-tooltip-inner > p:last-child {
  margin-bottom: 0;
}

.rc-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  bottom: 4px;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: black;
}

.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50%;
}

.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 15%;
}

.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 15%;
}

.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  left: 4px;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: black;
}

.rc-tooltip-placement-right .rc-tooltip-arrow {
  top: 50%;
}

.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}

.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 15%;
}

.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  right: 4px;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: black;
}

.rc-tooltip-placement-left .rc-tooltip-arrow {
  top: 50%;
}

.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}

.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 15%;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  top: 4px;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: black;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  left: 50%;
}

.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 15%;
}

.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 15%;
}

/* 
 * Toast
 */
.Toastify__toast-container {
  width: 450px;
  color: black;
}
.Toastify__toast {
  min-height: 45px;
  font-family: inherit;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(39, 41, 43, 0.16);
  border-radius: 10px;
  margin-bottom: 1rem;
  padding: 10px 16px;
}
.Toastify__toast-body {
  font-weight: 500;
  padding: 0;
}
.Toastify__toast-theme--light {
  color: black;
}
.Toastify__toast-icon {
  -webkit-margin-end: 14px;
  margin-inline-end: 14px;
}
.Toastify__close-button {
  align-self: center;
}
.Toastify__close-button > svg {
  display: block;
}

/* ENTERING ZONE OF EXTREMELY HACKY STUFF
This is custom CSS to add a banner on top of the Plaid Link iframe */

/* Need to override Plaid's global IFrame styles globally in order to be able to set a Z-index higher. 
   Using a local module stylesheet does not override from the top level. 
   and the Z-index modification doesn't work in all browsers. So, we have to shift it downwards.
   Not an ideal solution. */
iframe[title="Plaid Link"] {
  z-index: 100 !important;
  display: none;
}

#plaid-banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #d1ed64;
  padding: 14px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;

  /* Plaid automatically sets the Z-index to this value -- the maximum
        possible integer */
  z-index: 99999999 !important;
}

#plaid-banner strong {
  font-weight: 500;
}

/* Media Queries
–––––––––––––––––––––––––––––––––––––––––––––––––– */

@media (max-width: 1220px) {
  aside {
    width: 220px;
    margin-top: -10px;
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 0;
    display: block;
    border: 1px solid rgba(12, 53, 59, 0.2);
  }
}

/* Mobile */
@media (max-width: 600px) {
  body.fullheight #__next,
  body.flex #__next {
    display: block;
    overflow: auto;
  }

  body.hasoverlay #__next {
    overflow: hidden;
  }

  aside {
    width: 100%;
    margin: 18px 0 !important;
    display: block;
    float: none;
    border: none;
    border-top: 2px solid rgba(12, 53, 59, 0.1);
    border-bottom: 2px solid rgba(12, 53, 59, 0.1);
  }

  aside.optional {
    display: none;
  }

  .widecontainer-primary {
    width: 100%;
    flex: 0 0 100%;
    height: auto;
    display: block;
  }

  .opener h1 {
    padding-right: 0;
  }

  .opener .widecontainer-primary {
    width: 100%;
  }

  .widecontainer-secondary {
    display: none;
  }

  .fullsize > iframe {
    width: calc(100% - 40px);
    padding: 20px;
  }

  .guide-seperator {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .widecontainer-protrudingbg {
    display: none;
  }

  .valueprop .widecontainer {
    display: block;
  }

  .valueprop .widecontainer-primary {
    text-align: center;
  }

  .valueprop-illustration {
    display: none;
  }

  .valueprop .widecontainer-secondary {
    display: block;
    margin-top: 60px;
    width: 100%;
  }

  .widecontainer.title h1 {
    padding-right: 0;
  }

  .notice {
    display: block;
    margin-top: 60px;
    margin-bottom: 60px;
    padding-bottom: 0;
  }

  .Toastify__toast-container {
    width: 100vw;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

/* Tablet */
@media (max-width: 900px) {
  .opener.salary-negotation .widecontainer-primary {
    width: 100%;
    flex: 0 0 100%;
  }

  .opener.salary-negotation .widecontainer-secondary {
    display: none;
  }
}

@media (max-width: 600px) {
  .hideMobile {
    display: none;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
